@font-face {
    font-family: 'Quicksand-Semi-Bold';
    font-weight: bold;
    font-style: normal;
    src: url('../assets/fonts/Quicksand-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Quicksand-Bold';
    font-weight: bold;
    font-style: normal;
    src: url('../assets/fonts/Quicksand-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Quicksand-Light';
    font-style: normal;
    src: url('../assets/fonts/Quicksand-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Quicksand-Medium';
    font-weight: medium;
    font-style: normal;
    src: url('../assets/fonts/Quicksand-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Quicksand-Regular';
    font-weight: normal;
    font-style: normal;
    src: url('../assets/fonts/Quicksand-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Palatino-italico';
    font-weight: lighter;
    font-style: normal;
    src: local('Pacifico Regular'),
        url('../assets/fonts/Palatino-italico.ttf') format('truetype');
    font-display: swap;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Quicksand-Regular' !important;
    scroll-behavior: smooth;
    background-color: #fff;
}

/** Toast **/
.Toastify__toast {
    border-radius: 10px;
    margin-top: 70px;
}
